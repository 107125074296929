import { useSessionStore } from "@/portal/stores/session";
import { createRouter, createWebHistory } from "vue-router";

const profileAuthenticateRouteName = "ProfileAuthenticate";

const router = createRouter({
  // TODO: configure the base URL for the router.
  history: createWebHistory(),
  routes: [
    {
      name: 'Profile',
      path: '/profiles/:profileId',
      props: true,
      component: () => import('@/portal/views/Profile.vue'),
      children: [
        {
          path: "",
          name: "ProfileHome",
          props: true,
          component: () => import("@/portal/views/ProfileHome.vue"),
        },
        {
          path: "authenticate",
          name: profileAuthenticateRouteName,
          props: true,
          component: () => import("@/portal/views/ProfileAuthenticate.vue"),
        },
        // TODO: Add a route for the "NotFound" view.
      ],
    },
    // TODO: Add a route for the "NotFound" view.
  ],
});

// Set query token.
router.beforeEach((to, from, next) => {
  const queryToken = to.query.token
  if (queryToken && typeof queryToken === 'string' && queryToken.length > 0) {
    useSessionStore().$patch({ queryToken })
  }
  next();
});

export default router;
